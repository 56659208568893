<template>
    <div class="data-page-content" style="padding-bottom: 20px;">
        <div class="rowFlex" style="justify-content: center;">
            <div style="margin-right: 40px ;">
                <div class="rowFlex top">
                    <!-- 租凭情况 -->
                    <div class="data-item-wrap">
                        <DataItemPanelLeftBorder/>
                        <div class="tenant-panel">
                            <div class="tenant-panel-header">近30天停车数据</div>
                            <div id="tenants"></div>
                            <div class="swiper-pass-log">
                                <div class="swiper-pass-log-wrapper">
                                    <div v-for="(log, logIndex) in carLog" :key="log.id+'_'+logIndex"
                                         :class="['swiper-slide swiper-no-swiping', log.id+'_'+logIndex, {insert: logIndex === 0 && isInsertCarLog}]"
                                    >
                                        <ul>
                                            <li>{{ log.outTime == '00:00' ? log.inTime : log.outTime }}</li>
                                            <li>{{ log.carNumber.replace(log.carNumber.slice(3, 5), '∗∗') }}</li>
                                            <li>{{ log.type == 1 ? '临时车' : log.type == 2?'月租车':'免费车' }}</li>
                                            <li>{{ log.isOut }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 传感器设备 -->
                    <div class="equipment-data-panel">
                        <div class="equipment-data-panel-content">
                            <div>
                                <div class="top">
                                    <span>AI智能监控</span>
                                    <span>{{cameraCount}}个</span>
                                </div>
                                <div class="right">
                                    <span>闸机门禁</span>
                                    <span>{{ deviceManageCount }}个</span>
                                </div>
                                <div class="left">
                                    <span>智能传感器</span>
                                    <span>{{ AISensorCount }}个</span>
                                </div>
                                <div class="bottom">
                                    <span>蓝牙定位</span>
                                    <span>{{ iBeaconCount }}个</span>
                                </div>
                            </div>
                        </div>
                        <img src="../../assets/bigData/1/sbdbbj.png" alt="">
                    </div>
                </div>
                <!--  进出记录  -->
                <div class="pass-log-wrap">
                    <DataItemPanelLeftBorder/>
                    <div class="pass-log">
                        <div class="pass-log-header data-item-title">最新进出记录</div>
                        <!--      -->
                        <div class="swiper-pass-log">
                            <div class="swiper-pass-log-wrapper">
                                <div v-for="(log, logIndex) in passLog" :key="log.id+'_'+logIndex"
                                     :class="['swiper-slide swiper-no-swiping', log.id+'_'+logIndex, {insert: logIndex === 0 && isInsertPassLog}]"
                                >
                                    <ul>
                                        <li>{{ log.time.slice(11) || '-' }}</li>
                                        <li>{{ log.userName ? log.userName.slice(0, 1).padEnd(3, '∗') : '陌生人' }}</li>
                                        <li v-if="log.phone">{{ log.phone }}</li>
                                        <li v-if="log.type">{{ logPersonType[log.type] }}</li>
                                        <li v-if="log.idCard">{{ log.idCard }}</li>
                                        <li v-if="log.company">{{log.company.length < 7 ? log.company :
                                            log.company.replace(log.company.slice(3, log.company.length - 4), '∗∗∗∗')}}
                                        </li>
                                        <!--                    <li v-if="log.address">{{ log.address }}</li>-->
                                        <li v-if="log.method">{{ logMethod[log.method] }}</li>
                                        <li v-if="log.way">{{ logWay[log.way] }}</li>
                                        <li v-if="log.buildingCode">{{log.buildingCode}}</li>
                                        <li v-if="log.bodyTemperature">{{ log.bodyTemperature }}℃</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: -6px ">
                <div class="data-item-wrap rowFlex" style="margin-bottom: 20px ">
                    <DataItemPanelLeftBorder/>
                    <div class="personal-type-panel" style="height: 280px">
                        <div class="personal-type-panel-header data-item-title" style="margin-bottom: 30px">人脸监测数据</div>
                        <!--            <div id="personal-type"></div>-->
                        <div class="personal-wrapper">
                            <div class="personal-wrapper-left" style="position: relative">
                                <div id='personalType'></div>
                                <div class="personalDate">
                                    <div class="personal-item" v-for="item in  faceDataBig.weekData" :key="item.date">
                                        {{item.date}}
                                    </div>
                                    <div class="active-per">
                                        <img src="./images/jianTou.png" class="imgTop">
                                        <img src="./images/jianTou.png" class="imgBottom">
                                    </div>
                                </div>

                            </div>
                            <div class="personal-wrapper-right">
                                <div class="per-right-item">
                                    <img src="./images/zhizhen.png">
                                    <div style="margin-right: 30px">
                                        当前检测数据
                                    </div>
                                    <div>
                                        {{faceDataBig.weekData?faceDataBig.weekData[indexE].dataTotal:'0'}}Mb
                                    </div>
                                </div>
                                <div class="per-right-item">
                                    <img src="./images/zhizhen.png">
                                    <div style="margin-right: 30px">
                                        今日人脸抓拍
                                    </div>
                                    <div>
                                        {{faceDataBig.todayData || faceDataBig.todayData == 0 ? faceDataBig.todayData :
                                        '0'}}张
                                    </div>
                                </div>
                                <div class="per-right-item">
                                    <img src="./images/zhizhen.png">
                                    <div style="margin-right: 30px">
                                        昨日人脸抓拍
                                    </div>
                                    <div>
                                        {{faceDataBig.yesterdayData || faceDataBig.yesterdayData == 0
                                        ?faceDataBig.yesterdayData : '0'}}张
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="data-item-wrap rowFlex">
                    <DataItemPanelLeftBorder/>
                    <div class="personal-pass-panel">

                        <div class="personal-pass-panel-header data-item-title">近30天人脸监测统计</div>
                        <div id="personal-pass-type"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 异常抓拍 -->
        <div class="unusual-capture-wrap">
            <DataItemPanelLeftBorder/>
            <div class="unusual-capture">

                <div class="unusual-capture-header data-item-title">人脸抓拍</div>
                <div class="unusual-capture-content">
                    <!--        <div class="capture-prev">
                              <img v-show="capturePhotoPageIndex > 1" vue-router-keep-alive-helper="../../assets/bigData/1/swiperL.png" alt="">
                            </div>-->
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide no-swiping"
                                 v-for="(item, index) in capturePhotoAbnormals" :key="index"
                            >
                                <!--                <div v-if="item.isSolve == 1" class="capture-status">已处理</div>-->
                                <img :src="snapImgHost + item.photoUrl" alt="">
                                <!--                <div class="capture-type">{{ capturePhotoType[item.type] }}</div>-->
                            </div>
                        </div>
                    </div>
                    <!--        <div class="capture-next">
                              <img v-show="capturePhotoAbnormals.length % 6 === 0" vue-router-keep-alive-helper="../../assets/bigData/1/swiperR.png" alt="">
                            </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import Swiper from 'swiper';
    import 'swiper/swiper.min.css';
    import DataItemPanelLeftBorder from "@/views/BigData/DataItemPanelLeftBorder";
    import {
        inSevenDayEnterDataUrl,
        queryAICameraCountUrl,
        queryBigDataCapturePhotoAbnormalUrl,
        queryDeviceCountUrl,
        queryGatePassByHourUrl,
        queryGatePassByTypeUrl,
        queryIBeaconCountUrl,
        queryPassingLogByIdUrl,
        querySensorCountUrl,
        snapImgHost,
        queryFaceDataBigDataUrl,
        queryNewInOutLogUrl, bigDataEquipmentCountUrl,
    } from "@/requestUrl";
    import {initWs} from "@/views/BigData/tools";

    let charts = [];
    export default {
        name: "BigDataFour",
        components: {
            DataItemPanelLeftBorder
        },
        async created() {
            // await this.getqueryFaceDataBigData();
        },
        data() {
            return {
                gatePassByHour: null,
                indexE: 0,
                monitorEchars: null,
                faceDataBig: {},//人脸监测数据
                guangTime: null,
                guangBiao: null,
                snapImgHost,
                noVacancy: '',  // 空置面积
                vacancy: '',  // 在租面积
                regionCode: this.$vc.getCurrentRegion().code,
                cameraCount: 0, // 摄像头数量
                deviceManageCount: 0, // 门禁/闸机数量
                AISensorCount: 0, // 传感器数量
                iBeaconCount: 0, // beacon设备数量
                capturePhotoAbnormals: [],   // 异常抓拍照片数量
                passLog: [],  // 通行记录,
                isInsertPassLog: false, // 是否是插入最新的通行记录
                carLog: [], // 进出车记录
                isInsertCarLog: false, // 是否插入最新进出车记录
                // capturePhotoPageIndex: 1, // 抓拍照片现在所在的页码
                capturePhotoType: {
                    1: '人员聚集',
                    2: '未戴口罩',
                    3: '吸烟',
                    4: '跌倒',
                    5: '相机被遮挡',
                    6: '区域入侵',
                    7: '车辆违停',
                    8: 'VIP',
                    9: '可疑人',
                },
                logMethod: {
                    1: '扫码',
                    2: '刷脸',
                    3: "刷卡",
                },
                logWay: {
                    1: '进',
                    2: '出'
                },
                logPersonType: {
                    1: '楼内用户',
                    2: '访客',
                    3: '物业员工'
                },
                socket: null,
            }
        },
        async mounted() {
            this.getqueryFaceDataBigData();
            this.initPage();
            this.guangBiao = document.getElementsByClassName('active-per')[0];
        },
        beforeDestroy() {
            clearInterval(this.guangTime);
            charts.forEach(chart => chart.dispose());
            charts = [];
            if (this.socket) {
                this.socket.close(3000, '页面关闭');
            }
        },

        methods: {
            //获取人脸监测数据
            getqueryFaceDataBigData() {
                return this.$fly.get(queryFaceDataBigDataUrl, {
                    regionCode: this.regionCode
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    console.log('人脸统计');
                    console.log(res.data);
                    this.faceDataBig = res.data;
                    this.monitorEchars = this.getFaceMonitoring();
                    this.gatePassByHour = this.queryGatePassByHour();
                })
            },
            async initPage() {
                if (this.socket) {
                    this.socket.close();
                    this.socket = null;
                }
                // this.initCaptureNavigation(captureSwiper);
                // 传感器
                this.querySensorCount(1);
                this.querySensorCount(2);
                this.querySensorCount(3);
                this.querySensorCount(4);

                Promise.all([
                    // this.queryGatePass(),
                    this.querySevenDayEnterCar(),
                    // this.queryGatePassByHour(),
                    // -------
                    this.queryCapturePhotoAbnormal(),
                    this.queryPassingLog(),
                    this.getNewCarInOutLog(),
                ])
                    .then(allRes => {

                        console.log('allRes');
                        console.log(allRes)
                        // const [gatePassChart, sevenDayEnterCarChart, passByHourChart] = allRes;
                        const [gatePassChart, passByHourChart] = allRes;
                        /*const logSwiper = this.initSwiper('.swiper-pass-log', {
                          direction: 'vertical',
                          slidesPerView: 4,
                          initialSlide: 1,
                          spaceBetween: 10,
                          autoplay: {
                            delay: 1000,
                          },
                          loop: true,
                          observer: true,
                          observeParents: true,
                          // reverseDirection: true,
                          wrapperClass: 'swiper-pass-log-wrapper',
                        });*/
                        const captureSwiper = this.initSwiper('.swiper-container', {
                            slidesPerView: 14,
                            spaceBetween: 32,
                            observer: true,
                            observeParents: true,
                            slidesPerGroup: 1,
                            roundLengths: true,
                            noSwipingClass: 'no-swiping',
                        });
                        this.socket = initWs({
                            messageHandler: res => {
                                try {
                                    let data = JSON.parse(res.data);
                                    const dataTypeHandlers = {
                                        cameraCount: () => {
                                            this.cameraCount = res.data;
                                        },
                                        passingLog: () => {
                                            this.passLog.unshift(data.data);
                                            this.isInsertPassLog = true;
                                            const logEl = document.getElementsByClassName(`${data?.id || null}_0`)[0];
                                            logEl.classList.add('insert');
                                            setTimeout(() => {
                                                logEl.classList.remove('insert');
                                                this.passLog.pop();
                                            }, 400)
                                            // logSwiper.updateSlides();
                                            // logSwiper.slidePrev();
                                        },
                                        /*异常抓拍数据: () => {
                                          this.capturePhotoAbnormals.splice(this.capturePhotoAbnormals.length - 1, 1, data.data);
                                          if (captureSwiper.activeIndex < captureSwiper.slides.length - 1) {
                                            captureSwiper.slideNext();
                                          }
                                        },*/
                                        //人脸数据监测
                                        faceDataStatistical() {
                                            console.log('更新人脸监测数据');
                                            this.getqueryFaceDataBigData();
                                        },
                                        houseCome: () => {
                                            const comeData = passByHourChart.getOption().series[1].data;
                                            comeData[dayjs().hour()] = data.data;
                                            passByHourChart.setOption({
                                                series: [
                                                    passByHourChart.getOption().series[0],
                                                    {
                                                        name: '出',
                                                        type: 'line',
                                                        data: comeData,
                                                        smooth: true,
                                                        lineStyle: {
                                                            width: 3,
                                                        }
                                                    },
                                                ]
                                            });
                                        },
                                        capturePhoto: () => {
                                            this.capturePhotoAbnormals.splice(0, 0, ...data.data.dataList);

                                            if (captureSwiper.activeIndex < captureSwiper.slides.length - 1) {
                                                captureSwiper.slideNext();
                                            }
                                            if (this.capturePhotoAbnormals.length > 14) {
                                                this.capturePhotoAbnormals.splice(this.capturePhotoAbnormals.length - 1, 1);
                                            }
                                            console.log('人脸抓拍');
                                            console.log(data.data.dataList);
                                            // this.capturePhotoAbnormals.unshift(data.data);
                                            // captureSwiper.slidePrev();
                                        },
                                        houseEntry: () => {
                                            const entryData = passByHourChart.getOption().series[0].data;
                                            entryData[dayjs().hour()] = data.data;
                                            passByHourChart.setOption({
                                                series: [
                                                    {
                                                        name: '进',
                                                        type: 'line',
                                                        data: entryData,
                                                        smooth: true,
                                                        lineStyle: {
                                                            width: 3,
                                                        }
                                                    },
                                                    passByHourChart.getOption().series[1],
                                                ]
                                            });
                                        },
                                        gatePassType: () => {
                                            // gatePassChart.setOption({
                                            //     series: [
                                            //         {
                                            //             //map(item => item.counts)
                                            //             data: [
                                            //                 {value: data.data.temporary}
                                            //             ]
                                            //         }
                                            //     ]
                                            // })
                                        },
                                        beaconCount: () => {
                                            this.iBeaconCount = data.data;
                                        },
                                        deviceCount: () => {
                                            this.deviceManageCount = data.data;
                                        },
                                        parkingData: () => {
                                            this.gatePassChart.setOption({
                                                series: [
                                                    {
                                                        data: data.data.data.map(item => item.counts),
                                                    }
                                                ]
                                            })
                                        },
                                        parkingInEnterLog: () => {
                                            this.carLog.unshift(data.data);
                                            this.isInsertCarLog = true;
                                            const logEl = document.getElementsByClassName(`${data?.id || null}_0`)[0];
                                            logEl.classList.add('insert');
                                            setTimeout(() => {
                                                logEl.classList.remove('insert');
                                                this.passLog.pop();
                                            }, 400)
                                        },
                                        equipmentCount: () => {
                                            this.AISensorCount = data.data;
                                        }
                                    }
                                    try {
                                        dataTypeHandlers[data.method]();
                                        console.log(data.method, '可执行');
                                    } catch (err) {
                                        console.log('报错', err)
                                    }
                                } catch (err) {
                                    console.log(err, '解析失败')
                                }
                            }
                        });
                    })
            },
            /**
             * 抓拍轮播按钮绑定事件
             * */
            /*initCaptureNavigation(swiper) {
              document.querySelector('.capture-prev').addEventListener('click', async () => {
                if (this.capturePhotoPageIndex > 1) {
                  await this.queryCapturePhotoAbnormal(this.capturePhotoPageIndex--);
                  swiper.slidePrev();
                }
              })
              document.querySelector('.capture-next').addEventListener('click', async () => {
                if (this.capturePhotoPageIndex < 9) {
                  await this.queryCapturePhotoAbnormal(this.capturePhotoPageIndex++);
                  swiper.slideNext();
                }
              })
            },*/
            //获取人脸数据监测
            getFaceMonitoring() {
                clearInterval(this.guangTime);
                this.indexE = 0;
                let left = 0;
                let speedS = document.getElementsByClassName('personalDate')[0].offsetWidth / 6;

                this.guangTime = setInterval(() => {
                    left = speedS * (this.indexE + 1);
                    if (this.indexE >= (this.faceDataBig.weekData.length - 1)) {
                        this.indexE = 0;
                        this.guangBiao.style.transition = 'all 0s'
                        this.guangBiao.style.left = 0 + 'px'
                        setTimeout(() => {
                            this.guangBiao.style.transition = 'all 0.3s';
                        }, 20)
                    } else {
                        ++this.indexE;
                        this.guangBiao.style.left = left + 'px'
                    }


                    this.monitorEchars.setOption({
                        series: [
                            {
                                data: [{value: +this.faceDataBig.weekData[this.indexE].dataTotal}]
                            },

                        ]
                    })
                    if (left > speedS * 6) {
                        // this.guangBiao.style.transition = 'all 0s'
                        // this.guangBiao.style.left = 0 + 'px'
                        // setTimeout(() =>{
                        //   this.guangBiao.style.transition = 'all 0.3s';
                        // },20)

                    }
                }, 3000)
                return this.initEChart('personalType', {
                    grd: {
                        top: 8,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        containLabel: true
                    },
                    series: [
                        {
                            type: 'gauge',
                            radius: '100%',
                            startAngle: 180,
                            endAngle: 0,
                            min: 0,
                            splitNumber: 2,//显示数量
                            itemStyle: {
                                color: '#58D9F9',//仪表阴影
                                shadowColor: 'rgba(0,138,255,0.45)',
                                shadowBlur: 10,
                                shadowOffsetX: 2,
                                shadowOffsetY: 2
                            },
                            progress: {
                                show: true,
                                roundCap: true,
                                width: 18,//蓝色的仪表线宽

                            },
                            pointer: {
                                icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                                length: '75%',

                                width: 16,//指针
                                offsetCenter: [0, '5%']
                            },
                            axisLine: {
                                roundCap: true,
                                lineStyle: {
                                    width: 18//白色仪表宽度
                                }
                            },
                            axisTick: {
                                splitNumber: 2,
                                lineStyle: {
                                    width: 2,
                                    color: '#ffffff'//仪表刻度
                                }
                            },
                            splitLine: {
                                length: 12,
                                lineStyle: {
                                    width: 3,
                                    color: '#ffffff'//仪表刻度
                                }
                            },
                            axisLabel: {
                                distance: 30,
                                color: '#ffffff',//内部仪表数据【0-240】
                                fontSize: 20
                            },
                            title: {
                                show: false
                            },
                            detail: {
                                formatter: function (value) {
                                    return '';
                                },
                                rich: {
                                    fontSize: 10,
                                }
                            },
                            data: [
                                {
                                    value: 0
                                }
                            ]
                        }
                    ]
                })
            },
            // 获取异常抓拍数据
            queryCapturePhotoAbnormal(pageNo = 1, pageSize = 14) {
                this.$fly.get(queryBigDataCapturePhotoAbnormalUrl, {
                    regionCode: this.regionCode,
                    pageNo,
                    pageSize
                })
                    .then(res => {
                        console.log('全部人脸数据');
                        console.log(res.data);
                        if (res.code !== 0) {
                            return false;
                        }
                        if (pageSize === 14) {
                            this.capturePhotoAbnormals = res.data.dataList;
                            return false;
                        }
                        return false;
                    })
            },
            // 查询最新出入记录
            queryPassingLog() {
                return this.$fly.get(queryPassingLogByIdUrl, {
                    regionCode: this.regionCode,
                    id: -1,
                    size: 5,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return false;
                        }
                        if (this.passLog.length === 0) {
                            this.passLog = res.data;
                            return false;
                        }
                        return false;
                    })
            },
            // 近30天人脸数据监测
            queryGatePassByHour() {
                let comeCount = [],
                    enterCount = [];
                return this.initEChart('personal-pass-type', {
                    grid: {
                        top: 60,
                        left: 0,
                        right: 0,
                        bottom: 8,
                        containLabel: true
                    },

                    legend: {
                        data: [],
                        textStyle: {
                            color: '#ccc'
                        },
                        left: '0',
                        top: 0,
                    },
                    xAxis: {
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: '#ccc',
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',

                        axisLine: {
                            lineStyle: {
                                color: '#ccc'
                            }
                        },
                        axisLabel: {
                            formatter: '{value}',
                            color: '#fff'
                        }
                    },
                    series: [


                        {
                            name: 'bar',
                            type: 'bar',
                            barWidth: 4,
                            itemStyle: {
                                borderRadius: 5,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {offset: 0, color: '#CF8CDB'},
                                    {offset: 1, color: '#358FE8'}
                                ])
                            },
                            data: this.faceDataBig.halfMonthFaceData ? this.faceDataBig.halfMonthFaceData.map(item => item.count) : []
                        },
                        {
                            name: 'line',
                            type: 'bar',
                            barGap: '-100%',
                            barWidth: 10,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                    {offset: 0, color: 'rgba(20,200,212,0)'},
                                    {offset: 0.2, color: 'rgba(20,200,212,0.2)'},
                                    {offset: 1, color: 'rgba(20,200,212,0)'}
                                ])
                            },
                            z: -12,
                            data: this.faceDataBig.halfMonthFaceData ? this.faceDataBig.halfMonthFaceData.map(item => item.count) : []
                        },

                    ]
                })

            },
            // 获取通行身份占比
            queryGatePass() {
                return this.$fly.get(queryGatePassByTypeUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        return this.initEChart('personal-type', {
                            tooltip: {
                                trigger: 'item'
                            },
                            color: ['#FFEA00', '#009BFF'],
                            series: [
                                {
                                    name: '人员类型占比',
                                    type: 'pie',
                                    radius: ['45%', '72%'],
                                    itemStyle: {
                                        borderRadius: 2,
                                        borderWidth: 4,
                                        borderCap: 'butt',
                                        borderColor: '#021659'
                                    },
                                    label: {
                                        width: 80,
                                        formatter: '{b}{d}%',
                                        height: 200,
                                        align: 'left',
                                        overflow: 'break',
                                        fontSize: 18,
                                        show: true,
                                        position: 'outside',
                                    },
                                    labelLine: {
                                        show: true,
                                        /*length: 50,
                                        length2: 30,*/
                                    },
                                    data: [
                                        {value: res.data.visitorsCount, name: '访客', label: {color: '#FFEA00'}},
                                        {value: res.data.noVisitorsCount, name: '楼内用户', label: {color: '#009BFF'}},
                                    ],
                                }
                            ]
                        })
                    })
            },
            // 获取停车场数据
            querySevenDayEnterCar() {
                return this.$fly.get(inSevenDayEnterDataUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        console.log('停车数据');
                        console.log(res.data);
                        return this.initEChart('tenants', {
                            color: ['#5AAEF3', '#62D9AD'],
                            grid: {
                                top: 30,
                                left: 0,
                                right: 0,
                                bottom: 20,
                                containLabel: true
                            },
                            legend: {
                                data: [],
                                textStyle: {
                                    color: "#fff"
                                }
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    data: [],
                                    axisPointer: {
                                        type: 'shadow'
                                    },
                                    axisLine: {
                                        lineStyle: {
                                            color: '#fff'
                                        }
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    type: 'value',
                                    minInterval: 1,
                                    axisLabel: {
                                        formatter: '{value}',
                                        color: "#fff"
                                    }
                                }
                            ],

                            series: [
                                {
                                    name: 'bar',
                                    type: 'bar',
                                    barWidth: 4,
                                    // data: res.data.monthRent,
                                    //    假数据r
                                    data: res.data.map(item => item.counts),
                                    borderRadius: 5,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {offset: 0, color: '#CF8CDB'},
                                        {offset: 1, color: '#358FE8'}
                                    ])
                                },

                            ]
                        })
                    })
            },
            // 最新车辆进出记录
            getNewCarInOutLog() {
                this.$fly.get(queryNewInOutLogUrl, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }
                        this.carLog = res.data;
                    })
            },
            /**
             * 获取传感器数量
             * @param {Number} type
             * */
            querySensorCount(type) {
                const requestTypes = {
                    1: {url: queryAICameraCountUrl, dataName: 'cameraCount'},
                    2: {url: queryDeviceCountUrl, dataName: 'deviceManageCount'},
                    3: {url: querySensorCountUrl, dataName: 'AISensorCount'},
                    4: {url: queryIBeaconCountUrl, dataName: 'iBeaconCount'},
                };
                this.$fly.get(requestTypes[type].url, {
                    regionCode: this.regionCode,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this[requestTypes[type].dataName] = res.data;
                    })
            },
            initEChart(elName, options) {
                const myChart = echarts.init(document.getElementById(elName));
                myChart.setOption(options);
                charts.push(myChart);
                return myChart;
            },
            initSwiper(elName, options = {}) {
                const tempSwiper = new Swiper(elName, options);
                return tempSwiper;
            },
        }

    }
</script>

<style lang="stylus" scoped>
    @import "./common.styl";

    // 租聘情况
    .tenant-panel
        width 560px
        position relative
        height 385px
        box-sizing border-box
        overflow hidden
        display inline-block
        background rgba(7, 65, 182, 0.25)
        box-shadow inset 0 0 50px 0 rgba(0, 255, 255, .2)
        border-right 1px solid rgba(26, 201, 255, 1)
        padding 20px
        color #fff
        font-size 20px
        margin-right 40px
        position relative

        &-header
            @extends .data-item-title
            margin-bottom 20px

        & > div
            & > span
                &:not(:last-of-type)
                    margin-right 60px

        #tenants
            height 224px

        .swiper-pass-log
            height 190px
            overflow hidden

            .swiper-slide
                height unset

                &.insert
                    animation insetPassLog .4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;

                &:not(:last-of-type)
                    margin-bottom 15px

            ul
                list-style none
                font-size 0
                position relative
                overflow hidden
                @extends .rowFlex
                padding 0 10px
                justify-content space-evenly
                //@extends .rowFlex
                //box-shadow inset 0 0 2.212vw 0 rgba(0, 255, 255, .2)
                background linear-gradient(180deg, rgba(39, 247, 225, .2) 0%, rgba(255, 255, 255, 0) 48%, rgba(39, 247, 225, .2) 100%);
                transition transform .4s 40ms

                &:hover
                    transform scale(1.06)

                &::before
                    position absolute
                    left 0
                    top 50%
                    width 14px
                    height 100%
                    content " "
                    background url("../../assets/bigData/1/zkh.png") no-repeat
                    background-size 100% 100%
                    transform translateY(-50%)

                &::after
                    position absolute
                    right 0
                    top 50%
                    width 14px
                    height 100%
                    content " "
                    background url("../../assets/bigData/1/ykh.png") no-repeat
                    background-size 100% 100%
                    transform translateY(-50%)

                li
                    font-size 20px
                    color #fff
                    padding 8px 10px
                    line-height 1
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
                    flex 0 0 auto

                    &:not(:last-of-type)
                        margin-right 0.5vw

    @keyframes rotate180
        0%
            transform translate(-50%, -50%)
        /*50%
          transform translate(-50%, -50%) rotate(180deg)*/
        100%
            transform translate(-50%, -50%) rotate(360deg)


    // 设备数据（传感器，监控）
    .equipment-data-panel
        text-align center
        display inline-block
        vertical-align top
        //border 1px dotted rgba(1, 225, 242, 1)
        width 620px
        height 350px
        padding 26px 80px 10px 80px
        //border-right 1px solid rgba(26, 201, 255, 1)
        position relative
        background no-repeat url("../../assets/bigData/1/ceshi.gif")
        background-size 100% 100%

        &-content
            width 458px
            margin 0 auto
            height 312px
            background url("../../assets/bigData/1/sbbj.png") no-repeat
            background-size 100% 100%
            color #fff
            @extends .rowFlex
            justify-content center
            position relative

            &::after, &::before
                background-repeat no-repeat
                border-radius 50%
                position absolute
                top 50%
                left 50%
                transform translate(-50%, -50%)
                transform-origin "center center"

            &::after
                content url("../../assets/bigData/1/sbdq.png")
                animation rotate180 12s linear 1s infinite normal both

            &::before
                content url("../../assets/bigData/1/sbxq.png")
                animation rotate180 12s linear 3s infinite reverse both

            & > div
                width 220px

                div
                    width 110px
                    position relative
                    box-sizing border-box
                    display inline-block
                    vertical-align top
                    text-align center
                    padding 10px 5px

                    & > span:first-of-type
                        width 90px
                        height 30px
                        box-sizing border-box
                        position relative
                        line-height 30px
                        margin-bottom 10px
                        background linear-gradient(180deg, rgba(39, 247, 225, .3) 0%, rgba(255, 255, 255, 0) 48%, rgba(39, 247, 225, .3) 100%);

                        &::before
                            position absolute
                            left 0
                            top 50%
                            transform translateY(-50%)
                            content " "
                            width 6px
                            height 30px
                            background url("../../assets/bigData/1/zkh.png") no-repeat
                            background-size 100% 100%

                        &::after
                            position absolute
                            right 0
                            top 50%
                            transform translateY(-50%)
                            content " "
                            width 6px
                            height 30px
                            background url("../../assets/bigData/1/ykh.png")
                            background-size 100% 100%

                    &::before, &::after
                        position absolute
                        content " "

                    &.top
                        &::before
                            width 2px
                            right 0
                            bottom 0
                            height 110px
                            background linear-gradient(177deg, rgba(39, 247, 225, 0.1) 0%, #27F7E1 100%)

                        &::after
                            width 4px
                            height 10px
                            background #27F7E1
                            right -1px
                            bottom -4px

                    &.right
                        padding-left 0

                        &::before
                            height 2px
                            left 0
                            bottom 0
                            width 110px
                            background linear-gradient(177deg, #27F7E1 0%, rgba(39, 247, 225, 0.1) 100%)

                    &.left
                        &::before
                            top -2px
                            right 0
                            height 2px
                            width 110px
                            background linear-gradient(177deg, rgba(39, 247, 225, 0.1) 0%, #27F7E1 100%)

                    &.bottom
                        padding-left 0

                        &::before
                            top 0
                            left -2px
                            width 2px
                            height 110px
                            background linear-gradient(177deg, #27F7E1 0%, rgba(39, 247, 225, 0.1) 100%)

                        &::after
                            width 10px
                            height 4px
                            background #27F7E1
                            left -6px
                            top -3px

                    span
                        display inline-block

        & > img
            position absolute
            width 416px
            left 50%
            transform translateX(-50%)
            bottom -134px

    //    异常抓拍
    .unusual-capture
        background rgba(7, 65, 182, .2)
        height 206px
        padding 20px
        width 1830px
        box-shadow inset 0 0 50px 0 rgba(0, 255, 255, .2)
        border-top-right-radius 5px
        border-bottom-right-radius 5px

        &-wrap
            justify-content center
            @extends .rowFlex
            margin 0 auto
            margin-top 26px

        &-content
            width 100%
            margin-top 20px
            display flex
            flex-flow row nowrap
            align-items center

            .swiper-container
                width 100%

                .swiper-slide
                    width 100px
                    height 120px
                    //border 0.044vw solid #ff00ff
                    text-align center
                    color #fff
                    display inline-block
                    position relative
                    border-radius 5px
                    overflow hidden /*transition transform .4s 40ms
          &:hover
            transform scale(1.2)*/

                    .capture-status
                        background rgba(0, 182, 148, 1)
                        color #fff
                        font-size 18px
                        padding 2px 3px
                        position absolute
                        right 0
                        top 0
                        box-sizing border-box
                        min-width 60px
                        min-height 22px

                    .capture-type
                        background rgba(241, 141, 31, 1)
                        color #fff
                        box-sizing border-box
                        min-width 40px
                        min-height 16px
                        font-size 12px
                        padding 2px 3px
                        position absolute
                        right 0
                        bottom 0

                    img
                        width 100%
                        height 100%

    // 进出人员占比
    .personal-type-panel, .personal-pass-panel
        width 560px
        height 320px
        overflow hidden
        background rgba(7, 65, 182, 0.25)
        box-shadow inset 0 0 50px 0 rgba(0, 255, 255, .2)
        padding 20px
        color #fff
        font-size 24px
        vertical-align top
        position relative
        border-right 1px solid rgba(26, 201, 255, 1)

    #personal-type
        height 227px

    .personal-pass-panel
        height 372px

        #personal-pass-type
            height 280px

        @keyframes insetPassLog
            0%
                height 0
                margin-bottom 0
                opacity 0
            60%
                height 18.76px
                margin-bottom 10px
            100%
                opacity 1
                margin-bottom 15px

    // 通行记录
    .pass-log
        width 1220px
        text-align left
        padding 18px
        height 260px
        background url("../../assets/bigData/1/yczp.png") no-repeat
        background-size 100% 100%
        border-radius-top-right 5px
        border-radius-bottom-right 5px

        &-wrap
            @extends .rowFlex
            margin-top 58px

        &-header
            line-height 1

        .swiper-pass-log
            height 190px
            overflow hidden

            .swiper-slide
                height unset

                &.insert
                    animation insetPassLog .4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;

                &:not(:last-of-type)
                    margin-bottom 15px

            ul
                list-style none
                font-size 0
                position relative
                overflow hidden
                @extends .rowFlex
                padding 0 10px
                //@extends .rowFlex
                //box-shadow inset 0 0 2.212vw 0 rgba(0, 255, 255, .2)
                background linear-gradient(180deg, rgba(39, 247, 225, .2) 0%, rgba(255, 255, 255, 0) 48%, rgba(39, 247, 225, .2) 100%);
                transition transform .4s 40ms

                &:hover
                    transform scale(1.06)

                &::before
                    position absolute
                    left 0
                    top 50%
                    width 14px
                    height 100%
                    content " "
                    background url("../../assets/bigData/1/zkh.png") no-repeat
                    background-size 100% 100%
                    transform translateY(-50%)

                &::after
                    position absolute
                    right 0
                    top 50%
                    width 14px
                    height 100%
                    content " "
                    background url("../../assets/bigData/1/ykh.png") no-repeat
                    background-size 100% 100%
                    transform translateY(-50%)

                li
                    font-size 20px
                    color #fff
                    padding 8px 10px
                    line-height 1
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
                    flex 0 0 auto

                    &:not(:last-of-type)
                        margin-right 0.5

    .personal-wrapper
        display flex
        justify-content space-between

        .personal-wrapper-left
            width 240px

            #personalType
                width 100%
                height 240px

            .personalDate
                position absolute
                left 0
                top 150px
                width 100%
                display flex
                font-size 12px
                color #FFFFFF
                display flex
                justify-content space-between

                .active-per
                    position absolute
                    transition all 0.3s
                    left 0
                    top -3px

                    img
                        position absolute
                        width 37px
                        height 7px

                    .imgTop
                        top -3px

                    .imgBottom
                        top 20px
                        transform rotateX(180deg)

        .personal-wrapper-right
            .per-right-item
                margin-top 36px
                display flex
                align-items center
                font-size: 20px;
                color: #02FAC0;

                &:nth-of-type(1)
                    margin-top 5px

                img
                    width 14px
                    height 20px
                    margin-right 10px
</style>
